
import { throttle } from '@/utils/utils'
import { addEventListener, removeEventListener } from '@/utils/queryEle'
import mixin from '@/mixins/rightNav'
export default {
  mixins: [mixin],
  data() {
    return {
      visible: false, // 回到顶部默认不可见
    }
  },
  computed: {
    getBackItems() {
      const tempArr = this.visible
        ? [
            {
              id: 999,
              name: '回到顶部',
              icon: 'right-nav-back-top',
            },
          ]
        : []
      return [...this.backItems, ...tempArr]
    },
  },
  mounted() {
    addEventListener(document, 'scroll', throttle(this.handleScroll, 200))
  },
  destroyed() {
    removeEventListener(document, 'scroll', throttle(this.handleScroll, 200))
  },
  methods: {
    handleScroll() {
      // 超过一屏才展示
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const clientHeight = document.body.clientHeight
      if (scrollTop > clientHeight) {
        this.visible = true
      } else {
        this.visible = false
      }
    },
    handleBack2Top() {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
  },
}
