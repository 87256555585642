/*
 * @Descripttion: 右侧导航公共逻辑
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-07-26 11:00:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-07 14:26:46
 */
import { mapState } from 'vuex'
import { urlEnhanceLang } from '@/utils/utils'
import allUrls from '@/utils/config'
import navConfig from '@/modules/config/nav'
import {
  ExpressserviceHoverbarClick,
  EcommerceserviceHoverbarClick,
  SFBuyserviceHoverbarClick,
  CooperateHoverbarClick,
} from '@/reporter/track'
const mixin = {
  data() {
    return {
      backtopHide: false,
      backItems: navConfig[this.$route.params.site].navList,
      tabList: navConfig[this.$route.params.site].tabList,
      kefu: navConfig[this.$route.params.site].kefu,
      customerUrl: navConfig[this.$route.params.site].customerUrl,
      site: '',
      lang: '',
      trackMap: {
        express: ExpressserviceHoverbarClick,
        ec: EcommerceserviceHoverbarClick,
        sfbuy: SFBuyserviceHoverbarClick,
        cooperate: CooperateHoverbarClick,
      },
    }
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
      isHome: (state) => state.isHome,
      locale: (state) => state.locale,
    }),
  },
  created() {
    this.site = this.$route.params.site
    this.lang = this.$route.params.lang
  },
  methods: {
    external(e) {
      if (e === 4) {
        // 商务合作
        this.trackFn('cooperate')
        const jumpUrl = urlEnhanceLang({
          lang: this.locale,
          url: this.customerUrl, // allUrls.iuopCustomerUrl,
        })
        window.open(jumpUrl)
      } else if (e === 3) {
        // 还需添加移动端判断
        if (this.resizeInfo.isMobile === true) {
          // 移动端
          if (this.tabList.length) {
            this.$refs.drawerRef.visible = true
          } else {
            this.kefu && window.open(this.kefu[this.lang])
          }
        } else {
          this.kefu && window.open(this.kefu[this.lang])
        }
      } else if (e === 6) {
        const routeUrl = this.$router.resolve({
          path: `/${this.site}/${this.lang}/support/suggestion`,
          query: {
            sceneCode: 'rightNav', // 场景码key
          },
        })
        location.href = routeUrl.href
      }
    },
    trackFn(name, properties) {
      if (this.trackMap[name]) {
        this.trackMap[name](properties)
      }
    },
    handleService(type) {
      this.trackFn(type)
      // 中国内地
      type === 'express' && this.live800()
      type === 'ec' && this.openTencent()
      type === 'sfbuy' && this.sfBuyService()

      // 印尼
      type === 'international' && this.openIdService('international') // 国际件
      type === 'domestic' && this.openIdService('domestic') // 本地件
    },
    // sfbuy客服
    sfBuyService() {
      let url = window.location.href
      url = encodeURIComponent(url)
      const url1 = allUrls.ocs2odpUrlSfbuy
      // window.g.ocs2odpUrl +
      // '/app/init?orgName=sy&channelId=140&clientType=1&accountId='
      const url2 = '&pagereferrer='
      window.open(
        url1 + url + url2 + url,
        'newwindow',
        'height=600,width=800,top=0,left=0,toolbar=no,menubar=no,location=no,resizable=yes,status=yes'
      )
    },
    // 物流客服
    live800() {
      const url = allUrls.ocs2odpUrlExpress
      window.open(
        url,
        'newwindow',
        'height=600,width=800,top=0,left=0,toolbar=no,menubar=no,location=no,resizable=yes,status=yes'
      )
    },
    // 电商
    openTencent() {
      const url = allUrls.ocs2odpUrlTencent
      // 'tencent://message/?uin=800013683&Site=%E7%BD%91%E7%AB%99&Menu=yes'
      window.open(url)
    },

    //
    openIdService(type) {
      switch (type) {
        case 'international':
          window.open(
            'http://kefu.sf.global/webim/im.html?configId=80ad35c6-9c2b-4032-9efb-d1e1cef03bd0'
          )
          break
        case 'domestic':
          window.open(
            'http://kefu.sf.global/webim/im.html?configId=53c908fe-b089-467d-8a09-2de89642070b'
          )
          break
      }
    },
  },
}
export default mixin
