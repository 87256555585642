import { render, staticRenderFns } from "./rightNav.vue?vue&type=template&id=176b3997&scoped=true&"
import script from "./rightNav.vue?vue&type=script&lang=js&"
export * from "./rightNav.vue?vue&type=script&lang=js&"
import style0 from "./rightNav.vue?vue&type=style&index=0&id=176b3997&prod&lang=less&"
import style1 from "./rightNav.vue?vue&type=style&index=1&id=176b3997&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "176b3997",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/SvgIcon.vue').default})
